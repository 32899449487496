import { AccountTypeService, FileAccount, PreAccountResponse, QuizService, UploadService } from '@wlp/ui-bs-signup';

import { Component } from '@angular/core';
import { LayoutConfigService } from 'src/app/core/layout/services/layout-config.service';
import { NavController, ModalController, AlertController  } from '@ionic/angular';
import { environment } from 'src/environments/environment';
import { ModalCombateAFraudeComponent } from '../../../../modals/modal-combate-a-fraude/modal-combate-a-fraudecomponent';
import { Storage } from '@ionic/storage';
import { catchError, tap } from 'rxjs/operators';
import { Subscription, of } from 'rxjs';
import { LoadingService } from 'src/app/core/layout/services/loading.service';

@Component({
  selector: 'ui-t-document-type',
  templateUrl: './document-type.page.html',
  styleUrls: ['./document-type.page.scss'],
})
export class DocumentTypePage {
  public isResidencial: boolean;

  private fileAccount: FileAccount;
  private isSelfiePhoto: boolean;
  private isRgOrCnh: boolean;
  private isLoadedResidencial: boolean;
  private preAccount: PreAccountResponse;
  private groupDocument: string;
  public brand: string;
  public navigationBackground: string;
  private optionalPages: boolean;

  private selfieUrl: string = ''
  private rgFrontUrl: string
  private rgBackUrl: string
  private cnhFrontUrl: string
  private cnhBackUrl: string
  private rneFrontUrl: string
  private rneBackUrl: string
  private ocrData: any
  private cpfValue: string
  private preAccountValue: string

  private buttonClick = true

  public selfToCombateAFraude = false
  public documentsFrontAndBack = false

  constructor(
    private navCtrl: NavController,
    private uploadService: UploadService,
    private accountTypeService: AccountTypeService,
    private layoutConfigService: LayoutConfigService,
    private modalController: ModalController,
    private alertController: AlertController,
    private storage: Storage,
    private quizService: QuizService,
    private loadingService: LoadingService,
  ) {
    this.brand = this.layoutConfigService.getConfig('wlTheme.timeline.brand.logo');
    this.navigationBackground = this.layoutConfigService.getConfig('wlTheme.timeline.background.image');
  }

  async ionViewWillEnter() {
    this.isResidencial = false;
    this.isSelfiePhoto = false;
    this.isRgOrCnh = false;
    // this.showResidencial();
    // this.uploadService.loadDocumentuploaded();
    this.optionalPages = environment.wlThemeParameterization.wlTheme.optionalPages;

    this.documentsFrontAndBack = sessionStorage.getItem('documentsFrontAndBack') === 'true' ? true : false

    this.selfToCombateAFraude = sessionStorage.getItem('selfToCombateAFraude') === 'true' ? true : false

    this.selfieUrl = sessionStorage.getItem('selfieUrl') !== null ? sessionStorage.getItem('selfieUrl') : ''

    this.rgFrontUrl = sessionStorage.getItem('rgFrontUrl') !== null ? sessionStorage.getItem('rgFrontUrl') : ''

    this.rgBackUrl =  sessionStorage.getItem('rgBackUrl') !== null ? sessionStorage.getItem('rgBackUrl') : ''
    
    this.cnhFrontUrl = sessionStorage.getItem('cnhFrontUrl') !== null ? sessionStorage.getItem('cnhFrontUrl') : ''

    this.cnhBackUrl =  sessionStorage.getItem('cnhBackUrl') !== null ? sessionStorage.getItem('cnhBackUrl') : ''
    
    this.rneFrontUrl = sessionStorage.getItem('rneFrontUrl') !== null ? sessionStorage.getItem('rneFrontUrl') : ''

    this.rneBackUrl =  sessionStorage.getItem('rneBackUrl') !== null ? sessionStorage.getItem('rneBackUrl') : ''

    this.ocrData =  sessionStorage.getItem('ocrData') !== null ? sessionStorage.getItem('ocrData') : ''

  }

  goBack() {
    // this.navCtrl.navigateRoot('account-type');
    this.navCtrl.back();
  }

  markButton(type: string): string {
    let isLoaded = this.isLoaded(type);
    if (type === 'RG') {
      isLoaded = this.documentsFrontAndBack
    }
    if (type === 'SELF_PHOTO'){
      isLoaded = this.selfToCombateAFraude
    }
    this.isDisabled();
    return isLoaded ? 'checkmark-circle' : 'ellipse-outline';
  }

  isDisabled(): boolean {
    this.isRgOrCnh = this.documentsFrontAndBack
    if (!this.isRgOrCnh) {
      this.isRgOrCnh = this.documentsFrontAndBack
    }
    this.isLoadedResidencial = false;
    this.isSelfiePhoto = this.isLoaded('SELF_PHOTO') || this.selfToCombateAFraude;
    if (this.isResidencial) {
      this.isLoadedResidencial = this.isLoaded('RESIDENCIAL_CERTIFICATE');
    }

    const disabled = this.isSelfiePhoto && this.isRgOrCnh && (this.isResidencial ? this.isLoadedResidencial : true);
    return !disabled;
  }

  private isLoaded(type: string): boolean {
    return this.uploadService.isDocumentUploaded(type);
  }

  // rGOrCPF() {
  //   this.fileAccount = new FileAccount();
  //   this.fileAccount.groupDocument = this.groupDocument;
  //   this.uploadService.createFileAccount(this.fileAccount);
  //   this.navCtrl.navigateRoot('choose-document');
  // }

  async rGOrCPF() {
    const alert = await this.alertController.create({
      header: 'Você irá enviar qual documento?',
      cssClass: 'custom-alert',
      buttons: [
        {
          text: 'RG',
          cssClass: 'alert-button-cancel',
          handler: () => {
            this.GoToModalCombateAFraude('rg')
          }
        },
        {
          text: 'CNH',
          cssClass: 'alert-button-confirm',
          handler: () => {
            this.GoToModalCombateAFraude('cnh')
          }
        },
        {
          text: 'RNE',
          cssClass: 'alert-button-confirm',
          handler: () => {
            this.GoToModalCombateAFraude('rne')
          }
        },
      ],
    });

    await alert.present();
  }

  proofAddress() {
    this.fileAccount = new FileAccount();
    this.fileAccount.uploadType = 'RESIDENCIAL_CERTIFICATE';
    this.fileAccount.positionPhoto = 'FRONT';
    this.fileAccount.title = 'Foto do Comprovante';
    this.uploadService.createFileAccount(this.fileAccount);
    this.navCtrl.navigateRoot('upload-documents');
  }

  photoSelf() {
    this.GoToModalCombateAFraude('passive')
    // this.fileAccount = new FileAccount();
    // this.fileAccount.uploadType = 'SELF_PHOTO';
    // this.fileAccount.positionPhoto = 'FRONT';
    // this.fileAccount.title = 'Foto da Selfie';
    // this.uploadService.createFileAccount(this.fileAccount);
    // this.navCtrl.navigateRoot('upload-documents');
  }

  async continue() {

     this.sendDocumentCaf()

    // if (
    //   this.preAccount.accountType.typeFlow.toString() === 'PHHYSICAL_PERSON' ||
    //   this.preAccount.accountType.typeFlow.toString() === 'PHYSICAL_PERSON'
    // ) {
    //   if (this.optionalPages) this.navCtrl.navigateRoot('comercial-agent');
    //   else this.navCtrl.navigateRoot('contract');
    //   return;
    // }

    // if (
    //   this.preAccount.accountType.typeFlow.toString() === 'MEI' ||
    //   this.preAccount.accountType.typeFlow.toString() === 'LEGAL_PERSON'
    // ) {
    //   this.navCtrl.navigateRoot('company-data');
    //   return;
    // }
  }

  toggleNav() {
    document.getElementById('toggle-nav').classList.toggle('open');
  }

  private showResidencial() {
    this.uploadService
      .getAccountType()
      .then((preAccount: PreAccountResponse) => this.defineResidecial(preAccount))
      .catch((err) => this.uploadService.getErroUpload(err));
  }

  private defineResidecial(preAccount: PreAccountResponse): void {
    this.preAccount = preAccount;
    if (
      preAccount.accountType.typeFlow.toString() === 'PHHYSICAL_PERSON' ||
      preAccount.accountType.typeFlow.toString() === 'PHYSICAL_PERSON'
    ) {
      this.groupDocument = 'PHYSICAL_PERSON';
      this.isMoreFiveK();
      return;
    }

    if (preAccount.accountType.typeFlow.toString() === 'MEI') {
      this.groupDocument = 'MEI';
      this.isResidencial = true;
      return;
    }
  }

  private isMoreFiveK() {
    this.accountTypeService
      .accountIsMoreFiveK()
      .then((res) => {
        if (res.isMore) {
          this.isResidencial = true;
        }
      })
      .catch((err) => this.accountTypeService.getErrorMessages(err));
  }

  public getImageTag(tag: string) {
    let item: any = this.layoutConfigService.getImagesPages(tag);
    return item.path;
  }

  public async GoToModalCombateAFraude(type: string) {

    const modal = await this.modalController.create({
      component: ModalCombateAFraudeComponent,
      componentProps: {
        type: type
      },
    });

    modal.onDidDismiss().then(async (data) => {
      if (type === 'passive') {
        if (data.data && data.role === 'ok') {
          this.selfToCombateAFraude = true
          sessionStorage.setItem('selfToCombateAFraude', this.selfToCombateAFraude.toString())
          this.selfieUrl = data.data.imageUrl
          sessionStorage.setItem('selfieUrl', this.selfieUrl)
        } else if (data.role === 'fail') {
          this.showAlertError();
        }
      }
      if (type === 'rg' || type === 'cnh' || type === 'rne') {

        if (data.data && data.role === 'ok') {
          this.documentsFrontAndBack = true
          sessionStorage.setItem('documentsFrontAndBack', this.documentsFrontAndBack.toString())
          if (type === 'rg') {
            this.rgFrontUrl = data.data[0].imageUrl
            this.rgBackUrl  = data.data[1].imageUrl
            sessionStorage.setItem('rgFrontUrl', this.rgFrontUrl)
            sessionStorage.setItem('rgBackUrl', this.rgBackUrl)
          } else if (type === 'cnh') {
            this.cnhFrontUrl = data.data[0].imageUrl
            this.cnhBackUrl  = data.data[1].imageUrl
            sessionStorage.setItem('cnhFrontUrl', this.cnhFrontUrl)
            sessionStorage.setItem('cnhBackUrl', this.cnhBackUrl)
          } else if (type === 'rne') {
            this.rneFrontUrl = data.data[0].imageUrl
            this.rneBackUrl  = data.data[1].imageUrl
            sessionStorage.setItem('rneFrontUrl', this.rneFrontUrl)
            sessionStorage.setItem('rneBackUrl', this.rneBackUrl)
          }
        } else if (data.role === 'fail') {
          this.showAlertError();
        }
      }

      this.sendDocumentCaf();
  
    });

    return await modal.present();

  }

  async sendDocumentCaf() {
    if(
      this.selfieUrl && this.rgFrontUrl && this.rgBackUrl
      ){
        this.loadingService.show();
        this.buttonClick = false
      await this.analyseDocumentOCR(this.rgFrontUrl, this.rgBackUrl, 'RG_FRONT', 'RG_BACK', this.selfieUrl)
    }
    if(
      this.selfieUrl &&  this.cnhFrontUrl && this.cnhBackUrl
      ){
        this.loadingService.show();
        this.buttonClick = false
      await this.analyseDocumentOCR(this.cnhFrontUrl, this.cnhBackUrl, 'CNH_FRONT', 'CNH_BACK', this.selfieUrl)
    }
    if(
      this.selfieUrl &&  this.rneFrontUrl && this.rneBackUrl
      ){
        this.loadingService.show();
        this.buttonClick = false
      await this.analyseDocumentOCR(this.rneFrontUrl, this.rneBackUrl, 'RNE_FRONT', 'RNE_BACK', this.selfieUrl)
    }
  }

  async showAlertError() {
    const alert = await this.alertController.create({
      header: 'Tente novamente!',
      message: 'Algo deu errado durante a validação, por favor tente novamente.',
      cssClass: 'custom-alert',
      buttons: ['OK'],
    });

    await alert.present();
  }

  async showAlertErrorCamera() {
    const alert = await this.alertController.create({
      header: 'Tente novamente!',
      message: 'Algo deu errado ao carregar sua câmera, por favor tente novamente.',
      cssClass: 'custom-alert',
      buttons: ['OK'],
    });

    await alert.present();
  }


  async analyseDocumentOCR(frontUrl, backUrl, typeFront, typeBack, selfieUrl?) {

    this.cpfValue = sessionStorage.getItem('cpfValue')

    this.preAccountValue = sessionStorage.getItem('keyForm')

    let postData = {
      "onboardingId": this.preAccountValue,
      "attributes": {
        "cpf": this.cpfValue.replace(/\D/g, '')
      },
      "files": [
        {
          "type": typeFront,
          "data": frontUrl
        },
        {
          "type": typeBack,
          "data": backUrl
        },
        {
          "type": "SELFIE",
          "data": selfieUrl || this.fileAccount.fileBase64.photo
        }
      ]
    }

    this.quizService.checkOcr(postData)
      .pipe(
        tap((res) => {
          this.ocrData = res
          sessionStorage.setItem('ocrData', JSON.stringify(this.ocrData))
          this.loadingService.hide();
          let onboarding_light = sessionStorage.getItem('onboarding_light')
          if(onboarding_light == 'true') {
            this.navCtrl.navigateRoot(['personal-address'])
           } else {
          this.navCtrl.navigateRoot('ocr-scanner');
            this.navCtrl.navigateRoot('ocr-scanner');
           }

        }),
        catchError((err) => {
          this.loadingService.hide();
          return of(null);
        })
      )
      .subscribe();
  }
    
}
