import { Component, Input, OnInit } from '@angular/core';
import { AlertController, LoadingController, NavController } from '@ionic/angular';
import { TransferLimitService } from '@wlp/ui-bs-transfer-limit';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'tab-transfers',
  templateUrl: './transfers.page.html',
  styleUrls: ['./transfers.page.scss'],
})

export class TransfersPage implements OnInit {
  transferLimitsType: any[];
  loading: boolean = true;
  selectedTransferType: string;
  transferLimitsData: any[];
  limitOptions: any[];
  billetAndWithdrawOptions: any[];
  @Input() uData: any;
  loadingObject: HTMLIonLoadingElement;

  constructor(
    private transferLimitService: TransferLimitService,
    private navCtrl: NavController,
    private alertController: AlertController,
    public loadingController: LoadingController,
  ) { }

  async ngOnInit() {
    this.loadingObject = await this.loadingController.create({
      message: 'Carregando...',
    });
    this.loadingObject.present();
    this.transferLimitService.getMyLimitsType('TRANSFER').pipe(tap((response) => {
      this.transferLimitsType = response;
      this.selectedTransferType = response[0].name;
      this.onTransferTypeChange(this.selectedTransferType);
      this.getBilletAndWithdraw();
      this.loading = false;
      this.loadingObject.dismiss();
    }, (error) => {
      this.showError(error.status.toString() + " - Ocorreu um erro ao processar sua solicitação");
    })).subscribe();
  }

  async showError(message) {
    const alert = await this.alertController.create({
      header: 'Ocorreu um erro',
      message: message,
      buttons: [
        {
          text: 'OK',
          role: 'confirm',
          handler: () => {
            this.navCtrl.back();
          },
        },
      ],
    });

    await alert.present();
  }

  onTransferTypeChange(event) {
    if(!this.loading){
      this.loadingObject.present();
      this.loading = true;
    }
    this.transferLimitService.getMyLimitsByType(event, this.uData['uuidWhiteLabel'], this.uData['uuidAccount'], 'DIURNO').pipe(tap((response) => {
      this.limitOptions = response['limits'];
      this.transferLimitService.getMyLimitsByType(event, this.uData['uuidWhiteLabel'], this.uData['uuidAccount'], 'NOTURNO').pipe(tap((response) => {
        response['limits'].forEach((limit) => {
          this.limitOptions.push(limit);
        });
        this.limitOptions.forEach((limit) => {
          limit['currentValuePerTransaction'] = parseFloat(limit['currentValuePerTransaction']).toFixed(2);
          limit['currentValuePerPeriod'] = parseFloat(limit['currentValuePerPeriod']).toFixed(2);
          limit['maximumValuePerTransaction'] = parseFloat(limit['maximumValuePerTransaction']).toFixed(2);
          limit['maximumValuePerPeriod'] = parseFloat(limit['maximumValuePerPeriod']).toFixed(2);
        });
        if(this.loading){
          this.loadingObject.dismiss();
          this.loading = false;
        }
      }, (error) => {
        this.showError(error.status.toString() + " - Ocorreu um erro ao processar sua solicitação");
      })).subscribe();
    }, (error) => {
      this.showError(error.status.toString() + " - Ocorreu um erro ao processar sua solicitação");
    })).subscribe();
  }

  getBilletAndWithdraw() {
    if(!this.loading){
      this.loadingObject.present();
      this.loading = true;
    }
    this.transferLimitService.getMyLimitsByType('WITHDRAW', this.uData['uuidWhiteLabel'], this.uData['uuidAccount'], 'DIURNO').pipe(tap((response) => {
      this.billetAndWithdrawOptions = response['limits'];
      this.transferLimitService.getMyLimitsByType('WITHDRAW', this.uData['uuidWhiteLabel'], this.uData['uuidAccount'], 'NOTURNO').pipe(tap((response) => {
        response['limits'].forEach((limit) => {
          this.billetAndWithdrawOptions.push(limit);
        });
        this.transferLimitService.getMyLimitsByType('BILLETS', this.uData['uuidWhiteLabel'], this.uData['uuidAccount'], '').pipe(tap((response) => {
          response['limits'].forEach((limit) => {
            this.billetAndWithdrawOptions.push(limit);
          });
          this.billetAndWithdrawOptions.forEach((limit) => {
            limit['currentValuePerTransaction'] = parseFloat(limit['currentValuePerTransaction']).toFixed(2);
            limit['currentValuePerPeriod'] = parseFloat(limit['currentValuePerPeriod']).toFixed(2);
            limit['maximumValuePerTransaction'] = parseFloat(limit['maximumValuePerTransaction']).toFixed(2);
            limit['maximumValuePerPeriod'] = parseFloat(limit['maximumValuePerPeriod']).toFixed(2);
          });
          if(this.loading){
            this.loadingObject.dismiss();
            this.loading = false;
          }
        }, (error) => {
          this.showError(error.status.toString() + " - Ocorreu um erro ao processar sua solicitação");
        })).subscribe();
      }, (error) => {
        this.showError(error.status.toString() + " - Ocorreu um erro ao processar sua solicitação");
      })).subscribe();
    }, (error) => {
      this.showError(error.status.toString() + " - Ocorreu um erro ao processar sua solicitação");
    })).subscribe();
  }

}
