import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { IonCard, IonicModule } from '@ionic/angular';
import { UiBsCashInModule } from '@wlp/ui-bs-cashin';
import { ClientLimitRateService, UiBsCashOutModule } from '@wlp/ui-bs-cashout';
import { UiBsDashboardModule } from '@wlp/ui-bs-dashboard';
import { UiBsLoginModule } from '@wlp/ui-bs-login';
import { UiBsMyDataModule } from '@wlp/ui-bs-my-data';
import { UiBsProsperaModule } from '@wlp/ui-bs-prospera';
import { UiBsTranslateModule } from '@wlp/ui-bs-translate';
import { UiCAccountBalanceModule } from '@wlp/ui-c-account-balance';
import { UiCAsideMenuLeftModule } from '@wlp/ui-c-aside-menu-left';
import { UiCBarInfoModule } from '@wlp/ui-c-bar-info';
import { UiCCardInfoModule } from '@wlp/ui-c-card-info';
import { UiCDashboardHeaderModule } from '@wlp/ui-c-dashboard-header';
import { UiCDatePickerModule } from '@wlp/ui-c-date-picker';
import { HeaderUserInfoModule } from '@wlp/ui-c-header-user-info';
import { UiCModalModule } from '@wlp/ui-c-modal';
import { UiCMyDataModule } from '@wlp/ui-c-my-data';
import { UiCNotificationsModule } from '@wlp/ui-c-notifications';
import { UiCPixAdvertisementModalComponent, UiCPixAdvertisementModalModule } from '@wlp/ui-c-pix-advertisement-modal';
import { UiCRechargeModule } from '@wlp/ui-c-recharge';
import { UiCUserCardGridModule } from '@wlp/ui-c-user-card-grid';
import { UiCUserCardMenuModule } from '@wlp/ui-c-user-card-menu';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { BrMaskDirective, BrMaskerModule } from 'br-mask';
import { TooltipModule } from 'ng2-tooltip-directive';
import { NgxMaskIonicModule } from 'ngx-mask-ionic';

import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { UiBsPrizeDrawModule } from '@wlp/ui-bs-prizedraw';
import { UiCContainerBrandModule } from '@wlp/ui-c-container-brand';
import { UiCDeviceModule } from '@wlp/ui-c-device';
import { UiCModalLimitRateComponent, UiCModalLimitRateModule } from '@wlp/ui-c-modal-limit-rate';
import { UiCSendCodeModule } from '@wlp/ui-c-send-code';
import { QRCodeModule } from 'angularx-qrcode';
import { SharedModule } from 'src/app/core/shared/shared.module';
import { environment } from '../../../../../../environments/environment';
import { InterceptService } from '../../../../../core/intercept/intercept.service';
import { ComponentsModule } from '../../../../../core/shared/modules/components.module';
import { CardsModule } from './cards/cards.module';
import { BilletsListDataFilterPipe } from './cash-in/billets-list/billet-list-data-filter.pipe';
import { BilletsListPage } from './cash-in/billets-list/billets-list.page';
import { MySalesPage } from './cash-in/my-sales/my-sales.page';
import { RechargePage } from './cash-in/recharge/recharge.page';
import { BilletCreateDataFilterPipe } from './cash-out/charging/billet/billet-create/billet-create-data-filter.pipe';
import { FavoritesDataFilterPipe } from './cash-out/charging/favorites-data-filter.pipe';
import { ExtractModule } from './cash-out/extract/extract.module';
import { RechargeMobilePage } from './cash-out/mobile-recharge/mobile-recharge.page';
import { TransferModule } from './cash-out/transfer/transfer.module';
import { ValidSmsUserPage } from './cash-out/valid-sms/valid-sms-user.page';
import { ValidSmsPage } from './cash-out/valid-sms/valid-sms.page';
import { CpManagerRoutingModule } from './cp-manager-routing.module';
import { CpManagerPage } from './cp-manager.page';
import { DashboardPage } from './dashboard/dashboard.page';
import { DetectappPage } from './detectapp/detectapp.page';
import { ImportFilesListPage } from './import-files/import-files-list/import-files-list.page';
import { ImportFilesLotePage } from './import-files/import-files-lote/import-files-lote.page';
import { ModalComponent } from './import-files/modal/modal.component';
import { ImportFilesLotePageList } from './import-files/import-files-lote-list/import-files-lote-list.page';
import { InsurancePage } from './insurance/insurance.page';
import { MyDataPage } from './my-data/my-data.page';
import { NotificationsPage } from './notifications/notifications.page';
import { OnboardingEcPageModule } from './onboarding-ec/onboarding-ec.module';
import { FormPermissionsPage } from './permissions/permissions-form/form-permissions.page';
import { PermissionsPage } from './permissions/permissions-list/permissions.page';
import { PixModule } from './pix/pix.module';
import { TransactionApprovalListPage } from './transaction-approval/transaction-approval-list/transaction-approval-list.page';
import { ValidateQrcodePage } from './validate-qrcode/validate-qrcode.page';
import { WalletModule } from './wallet/wallet.module';
import { AccountClosurePage } from './account-closure/account-closure.page';

import { Device } from '@awesome-cordova-plugins/device/ngx';
import { UiBsCelCoinModule } from '@wlp/ui-bs-celcoin';
import { UiBsManagerFeatureModule } from '@wlp/ui-bs-manager-feature';

import { UiBsAccountClosureModule } from '@wlp/ui-bs-account-closure'
import { UiBsNoticeModule } from '@wlp/ui-bs-notice'
import { SecurityPage } from './account-security/security/security.page';
import { SecurityValidatePage } from './account-security/validade-security-operation/security-validate/security-validate.page';
import {MatDatepickerModule} from '@angular/material/datepicker';
import { MatFormFieldModule, MatInputModule, MatNativeDateModule } from '@angular/material';
import { TransferLimitPage } from './transfer-limit/transfer-limit.page';
import { TransferLimitService } from '@wlp/ui-bs-transfer-limit';
import { SpeJLimitManager } from '@wlp/ui-px-transfer-limit';
import { LimitManagementService } from 'src/app/views/themes/sentinel/pages/cp-manager/transfer-limit/limit-management.service';
import { LimitManagement } from './limit-management/limit-management.page';
import { TransfersPage } from './transfer-limit/tabs/transfers/transfers.page';
import { HistoryPage } from './transfer-limit/tabs/history/history.page';

@NgModule({
  imports: [
    AngularSvgIconModule.forRoot(),
    BrMaskerModule,
    // CardsModule,
    CommonModule,
    ComponentsModule,
    CpManagerRoutingModule,
    ExtractModule,
    FormsModule,
    HeaderUserInfoModule,
    UiCSendCodeModule,
    IonicModule,
    UiCContainerBrandModule,
    NgxMaskIonicModule.forRoot(),
    OnboardingEcPageModule,
    UiCDeviceModule,
    CardsModule,
    PixModule,
    ReactiveFormsModule,
    RouterModule,
    TooltipModule,
    TransferModule,
    UiBsCashInModule.forRoot({ device: 'WEB', config: environment }),
    UiBsCashOutModule.forRoot({ device: 'WEB', config: environment }),
    UiBsDashboardModule.forRoot({ device: 'WEB', config: environment }),
    UiBsMyDataModule.forRoot({ device: 'WEB', config: environment }),
    UiBsProsperaModule.forRoot({ device: 'WEB', config: environment }),
    UiBsLoginModule.forRoot({ device: 'WEB', config: environment }),
    UiBsTranslateModule.forRoot({ device: 'WEB', config: environment }),
    UiCAccountBalanceModule,
    UiCAsideMenuLeftModule,
    UiCBarInfoModule,
    UiCCardInfoModule,
    UiCDashboardHeaderModule,
    UiCDatePickerModule.forRoot({ environment: 'web' }),
    UiCModalModule,
    UiCMyDataModule,
    UiCNotificationsModule,
    UiCPixAdvertisementModalModule,
    UiCRechargeModule,
    UiCUserCardGridModule,
    HeaderUserInfoModule,
    UiCUserCardMenuModule,
    WalletModule,
    UiCModalLimitRateModule,
    UiBsPrizeDrawModule,
    QRCodeModule,
    UiBsCelCoinModule,
    UiBsManagerFeatureModule,
    SharedModule,
    UiBsAccountClosureModule,
    UiBsNoticeModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
    MatNativeDateModule
  ],
  declarations: [
    CpManagerPage,
    DashboardPage,
    NotificationsPage,
    MyDataPage,
    MySalesPage,
    RechargeMobilePage,
    InsurancePage,
    DetectappPage,
    RechargePage,
    BilletsListPage,
    BilletsListDataFilterPipe,
    BilletCreateDataFilterPipe,
    FavoritesDataFilterPipe,
    ValidSmsPage,
    ValidateQrcodePage,
    PermissionsPage,
    FormPermissionsPage,
    ImportFilesListPage,
    ImportFilesLotePage,
    ImportFilesLotePageList,
    TransactionApprovalListPage,
    LimitManagement,
    TransferLimitPage,
    TransfersPage,
    HistoryPage,
    ValidSmsUserPage,
    AccountClosurePage,
    ModalComponent,
    SecurityPage,
    SecurityValidatePage
  ],
  providers: [
    InterceptService,
    TransferLimitService,
    SpeJLimitManager,
    LimitManagementService,
    BrMaskDirective,
    Device,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptService,
      multi: true,
    },
    ClientLimitRateService,
  ],
  entryComponents: [UiCPixAdvertisementModalComponent, UiCModalLimitRateComponent, ModalComponent],
  bootstrap: [],
})
export class CpManagerModule {}
