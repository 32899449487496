import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'tab-history',
  templateUrl: './history.page.html',
  styleUrls: ['./history.page.scss'],
})
export class HistoryPage implements OnInit {
  @Input() uData: any;

  constructor() { }

  ngOnInit() {
  }

}
